import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Grid } from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import { SuccessMessage } from "./SuccessMessage";

export const BenchmarkCompletedRoute = observer(
  function BenchmarkCompletedRoute() {
    const app = useApp();
    const navigate = useNavigate();

    return (
      <Grid container justify="center" spacing={1}>
        <Grid item xs={12}>
          <SuccessMessage
            icon="fa:check"
            title="Du hast deinen Benchmark abgeschlossen, vielen Dank"
            message="Du erhältst innerhalb der nächsten Minuten einen Link zu Deiner Auswertung."
          />
        </Grid>
      </Grid>
    );
  }
);
