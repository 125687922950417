import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import "fontsource-montserrat/400.css";
import "fontsource-montserrat/700.css";
import "fontsource-montserrat/800.css";

import { App } from "./components/App";

import { registerIconPack } from "@opendash/icons";

main();

async function main() {
  //@ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("opendash")
  );
}

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/#hot-module-replacement
// if (import.meta.hot) {
//   import.meta.hot.accept();
// }
