import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FIELDS = [
  {
    label: "Name",
    key: "name",
    multi: false,
  },
  {
    label: "Name (kurz)",
    key: "name_short",
    multi: false,
  },
  {
    label: "Reihenfolge",
    key: "order",
    multi: false,
    number: true,
  },
  // {
  //   label: "Beschreibung",
  //   key: "description",
  //   multi: true,
  // },
  // {
  //   label: "YouTube Link",
  //   key: "youtube_url",
  //   multi: false,
  // },
  {
    label: "Antwort (1)",
    key: "answer_1_text",
    multi: true,
  },
  {
    label: "Antwort (2)",
    key: "answer_2_text",
    multi: true,
  },
  {
    label: "Antwort (3)",
    key: "answer_3_text",
    multi: true,
  },
  {
    label: "Antwort (4)",
    key: "answer_4_text",
    multi: true,
  },
  // {
  //   label: "Ergebnis (1)",
  //   key: "answer_1_response",
  //   multi: true,
  // },
  // {
  //   label: "Ergebnis (2)",
  //   key: "answer_2_response",
  //   multi: true,
  // },
  // {
  //   label: "Ergebnis (3)",
  //   key: "answer_3_response",
  //   multi: true,
  // },
  // {
  //   label: "Ergebnis (4)",
  //   key: "answer_4_response",
  //   multi: true,
  // },
];

export const AdminQuestionEditRoute = observer(
  function AdminQuestionEditRoute() {
    const app = useApp();
    const classes = useStyles();

    const { id } = useParams();

    const question = app.benchmarks.questions.find((o) => o.objectId === id);

    if (!question) {
      return (
        <ErrorMessage
          icon="fa:exclamation-circle"
          title="Frage nicht gefunden"
          message="Bitte den Bearbeiten Dialog neu öffnen."
        />
      );
    }

    return (
      <>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Kategorie</InputLabel>
          <Select
            value={question.category?.objectId || ""}
            onChange={(e) => {
              app.admin.updateQuestion(id, {
                category: {
                  __type: "Pointer",
                  className: "Category",
                  objectId: e.target.value as string,
                },
              });
            }}
            label="Kategorie"
          >
            {app.benchmarks.categories.map((c) => (
              <MenuItem key={c.objectId} value={c.objectId}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {FIELDS.map((field) => (
          <TextField
            key={field.key}
            type={field.number ? "number" : undefined}
            variant="outlined"
            margin="normal"
            fullWidth
            multiline={field.multi}
            label={field.label}
            // @ts-ignore
            defaultValue={question[field.key] || ""}
            onBlur={(e) => {
              if (field.number) {
                app.admin.updateQuestion(id, {
                  [field.key]: parseInt(e.target.value),
                });
              } else {
                app.admin.updateQuestion(id, { [field.key]: e.target.value });
              }
            }}
          />
        ))}
      </>
    );
  }
);
