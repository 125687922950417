import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { Link } from "react-router-dom";

export const Navigation = observer(function Navigation() {
  const app = useApp();

  // TODO: Check auth/permission

  if (app.parse.user?.isAdmin) {
    return (
      <>
        <Link to="admin">Admin</Link>
        <Link to="logout">Logout</Link>
      </>
    );
  }

  if (app.parse.user) {
    return (
      <>
        <Link to="benchmark">Benchmark</Link>
        <Link to="logout">Logout</Link>
      </>
    );
  }

  return (
    <>
      <a
        href="https://www.digitalradar-muensterland.de/"
        title="Zur Startseite von Digitalradar Münsterland"
        target="_blank"
        rel="noopener noreferrer"
      >
        Zur Startseite
      </a>
      {/* <Link to="benchmark">Benchmark</Link>
      <Link to="login">Login</Link> */}
    </>
  );
});
