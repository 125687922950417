import React from "react";

import { BrowserRouter as ReactRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { AppContextProvider } from "../context";
import { Router } from "./Router";
import { Layout } from "./Layout";
import { ThemeProvider } from "./ThemeProvider";
import { AppLoadingScreen } from "./AppLoadingScreen";
import { NotificationProvider } from "./NotificationProvider";

interface AppProps {}

export function App({}: AppProps) {
  return (
    <AppContextProvider>
      <ThemeProvider>
        <SnackbarProvider maxSnack={2}>
          <NotificationProvider />
          <AppLoadingScreen>
            <ReactRouter>
              <Layout>
                <Router />
              </Layout>
            </ReactRouter>
          </AppLoadingScreen>
        </SnackbarProvider>
      </ThemeProvider>
    </AppContextProvider>
  );
}
