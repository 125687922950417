import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Navigate, Outlet } from "react-router-dom";

export const BenchmarkEditRoute = observer(function BenchmarkEditRoute() {
  const app = useApp();

  React.useEffect(() => {
    app.benchmarks.fetchCurrentBenchmark();
  }, [app.benchmarks.benchmark?.objectId]);

  if (!app.benchmarks.benchmark) {
    return <Navigate to="/benchmark" />;
  }

  return <Outlet />;
});
