export class ParseError extends Error {
  public _parse_code: number;
  public _parse_message: string;

  constructor(code: number, message: string) {
    super(message);

    this._parse_code = code;
    this._parse_message = message;
  }
}
