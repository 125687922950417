import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { useApp } from "../context";

import { ErrorMessage } from "./ErrorMessage";
import { AdminHomeLayout } from "./AdminHomeLayout";

export const AdminHomeRoute = observer(function AdminHomeRoute({ children }) {
  const app = useApp();

  // TODO: Check auth/permission

  React.useEffect(() => {
    app.admin.init();
  }, []);

  if (!app.parse.user?.isAdmin) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Berechtigungs Fehler"
        message="Du musst Admin Nutzer sein, um diese Seite zu sehen."
      />
    );
  }

  return (
    <AdminHomeLayout>
      <Outlet />
    </AdminHomeLayout>
  );
});
