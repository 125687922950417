import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminRegionRoute = observer(function AdminRegionRoute() {
  const app = useApp();

  return (
    <AdminList
      title="Regionen"
      className="Region"
      navName="region"
      rows={app.benchmarks.regions}
    />
  );
});
