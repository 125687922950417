import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { Navigation } from "./Navigation";
import { AnimatedBackground } from "./AnimatedBackground";
import { FooterContent } from "./FooterContent";

const Header = styled.div`
  height: 90px;
  background: white;
  border-bottom: 1px solid #ebebeb;
  user-select: none;

  overflow: hidden;

  position: relative;
  z-index: 3;
`;

const HeaderInner = styled.div`
  height: 90px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const LogoHolder = styled.div`
  display: block;
  float: left;

  height: 90px;
  margin-right: 15px;

  img {
    height: 90px;
  }
`;

const HeaderTitle = styled.div`
  display: block;
  float: left;

  height: 90px;

  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;

  padding: 20px 0;

  a {
    display: inline-block;
    height: 50px;
    line-height: 50px;

    border-left: 1px solid #eee;
    color: var(--color-default-font);
    text-decoration: none;

    padding: 0 15px;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const HeaderNav = styled.nav`
  display: block;
  float: right;

  height: 90px;
  line-height: 90px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;

  a {
    display: inline-block;
    height: 90px;

    color: var(--color-default-font);
    text-decoration: none;

    padding: 0 15px;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Content = styled.div`
  padding: 20px 0;

  min-height: calc(100vh - 90px - 60px);

  position: relative;
  z-index: 2;
`;

const AnimatedBackgroundContainer = styled.div`
  /* opacity: 0.1; */
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  > div {
    height: 100vh;
    width: 100vw;
  }

  z-index: 1;
`;

export const Layout = React.memo(function Layout({ children }) {
  return (
    <>
      <Header>
        <Container>
          <HeaderInner>
            <LogoHolder>
              <img
                src={require("../assets/logo/digitalradar_münsterland_final_1x.png")}
                alt="Digitalradar Münsterland"
              />
            </LogoHolder>
            <HeaderTitle>
              <Link to="benchmark">Benchmark</Link>
            </HeaderTitle>
            <HeaderNav>
              <Navigation />
            </HeaderNav>
          </HeaderInner>
        </Container>
      </Header>
      <Content>
        <Container>{children}</Container>
      </Content>
      <FooterContent />
      <AnimatedBackgroundContainer>
        <AnimatedBackground />
      </AnimatedBackgroundContainer>
    </>
  );
});
