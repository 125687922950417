import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import ReactPlayer from "react-player";
import { useSprings, animated } from "react-spring";

import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { ErrorMessage } from "./ErrorMessage";
import { Icon } from "@opendash/icons";
import { BenchmarkEditCategoryQuestions } from "./BenchmarkEditCategoryQuestions";

const useStyles = makeStyles((theme) => ({
  categoryHeading: {},
  questionContainer: {
    display: "block",
    width: "100%",
  },
  questionCheck: {
    color: theme.palette.success.main,
    marginLeft: 10,
  },
  formControl: {
    display: "block",
  },
  radioGroup: {
    padding: 0,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
    display: "flex",
    width: "100%",

    // background: theme.palette.grey[100],
  },
  radioGroupLabel: {
    display: "block",
    width: "100%",
    margin: 0,
    padding: theme.spacing(1),

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",

    // "&.active": {
    //   background: theme.palette.background.paper,
    // },

    "&:nth-last-child(1)": {
      borderBottomWidth: 0,
    },

    [theme.breakpoints.up("md")]: {
      width: "25%",

      borderRightWidth: 1,
      borderRightStyle: "solid",
      borderRightColor: "#eee",

      "&:nth-last-child(1)": {
        borderRightWidth: 0,
      },
    },
  },
  radioGroupRadio: {
    float: "right",
    paddingTop: 0,
    paddingRight: 0,
  },
  videoWrapper: {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingTop: "56.25%",
  },
  videoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
  navButton: {
    marginRight: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
}));

export const BenchmarkEditCategoryRoute = observer(
  function BenchmarkEditCategoryRoute() {
    const app = useApp();
    const navigate = useNavigate();
    const classes = useStyles();

    const { categoryId } = useParams();

    const [currentQuestion, setCurrentQuestion] = React.useState<null | string>(
      null
    );

    const categories = app.benchmarks.categories;

    const category = categories.find((c) => c.objectId === categoryId);

    const nextCategoryId = category
      ? categories[categories.indexOf(category) + 1]?.objectId
      : null;

    const previousCategoryId = category
      ? categories[categories.indexOf(category) - 1]?.objectId
      : null;

    const questions = app.benchmarks.questions.filter(
      (q) => q.category?.objectId === category?.objectId
    );

    React.useEffect(() => {
      if (category && questions.length > 0) {
        const firstUnanswerd = questions.find(
          (q) => !app.benchmarks.answers[q.objectId]
        );

        if (firstUnanswerd) {
          setCurrentQuestion(firstUnanswerd.objectId);
        } else {
          setCurrentQuestion(questions[0].objectId);
        }
      } else {
        setCurrentQuestion(null);
      }
    }, [categoryId]);

    if (!category) {
      return (
        <ErrorMessage
          icon="fa:exclamation-circle"
          title="Kategorie nicht gefunden"
          message="..."
        />
      );
    }

    return (
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {category.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BenchmarkEditCategoryQuestions
            key={category.objectId}
            category={category}
            questions={questions}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.padding}>
            {previousCategoryId && (
              <Button
                className={classes.navButton}
                // variant="outlined"
                // color="primary"
                onClick={() => {
                  navigate(`/benchmark/edit/category/${previousCategoryId}`);
                }}
              >
                Vorherige Kategorie
              </Button>
            )}

            {!nextCategoryId && (
              <Button
                className={classes.navButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/benchmark/edit`);
                }}
              >
                Zur Übersicht
              </Button>
            )}

            {nextCategoryId && (
              <Button
                className={classes.navButton}
                onClick={() => {
                  navigate(`/benchmark/edit`);
                }}
              >
                Zur Übersicht
              </Button>
            )}

            {nextCategoryId && (
              <Button
                className={classes.navButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/benchmark/edit/category/${nextCategoryId}`);
                }}
              >
                Nächste Kategorie
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
);
