import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminIndustryRoute = observer(function AdminIndustryRoute() {
  const app = useApp();

  return (
    <AdminList
      title="Branchen"
      className="Industry"
      navName="industry"
      rows={app.benchmarks.industries}
    />
  );
});
