import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
  editMetaButton: {
    marginLeft: theme.spacing(1),
  },
  hero: {
    padding: "200px 20px",
    textAlign: "center",
  },
  successIcon: {
    color: theme.palette.success.main,
  },
}));

export const BenchmarkHomeRoute = observer(function BenchmarkHomeRoute() {
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={12}>
        <div className={classes.hero}>
          {/* <img
            src={require("../assets/logo/digitalradar_münsterland_final_1x.png")}
            alt="Digitalradar Münsterland"
          /> */}
          {/* <Typography variant="h1">POSITIONSBESTIMMUNG</Typography> */}
          <Typography
            variant="h2"
            component="h1"
            className={classes.marginBottom}
            style={{ marginBottom: 10 }}
          >
            Wo steht dein Unternehmen beim Thema Digitalisierung?
          </Typography>
          <Typography
            variant="body1"
            className={classes.marginBottom}
            style={{ marginBottom: 10 }}
          >
            Setze deinen Benchmark und erfahre wie weit Du mit deinen Maßnahmen
            im Vergleich zu andern Unternehmen in der Region oder in deiner
            Branche bist.
          </Typography>
          <Typography
            variant="body1"
            className={classes.marginBottom}
            style={{ marginBottom: 30 }}
          >
            Du erhältst einen Bericht mit wichtigen Handlungsempfehlungen.
          </Typography>
          <Typography variant="body1">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={() => {
                app.benchmarks.createBenchmark().then((id) => {
                  navigate("/benchmark/edit/meta");
                });
              }}
            >
              Jetzt starten!
            </Button>
          </Typography>
        </div>
      </Grid>
      {app.report.lastReport && (
        <Grid item xs={12}>
          <Paper>
            <List>
              <ListItem
                button
                onClick={() => {
                  if (app.report.lastReport) {
                    const { id, secret } = app.report.lastReport;
                    navigate(`/report/${id}/${secret}`);
                  }
                }}
              >
                <ListItemText
                  primary={"Dein letzter Report"}
                  // primary={`Report vom ${"xxx"}`}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (app.report.lastReport) {
                        const { id, secret } = app.report.lastReport;
                        navigate(`/report/${id}/${secret}`);
                      }
                    }}
                  >
                    Report öffnen
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </Grid>
      )}
      {Object.values(app.benchmarks.benchmarks).length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <List>
              {Object.values(app.benchmarks.benchmarks)
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).valueOf() -
                    new Date(a.createdAt).valueOf()
                )
                .map((benchmark) => {
                  const date = new Intl.DateTimeFormat("de-DE").format(
                    new Date(benchmark.createdAt)
                  );
                  const answerCount = Object.keys(benchmark.answers || {})
                    .length;
                  const questionCount = app.benchmarks.questions.length;
                  const onClick = () => {
                    app.benchmarks.currentBenchmark = benchmark.objectId;
                    navigate("/benchmark/edit");
                  };

                  return (
                    <ListItem key={benchmark.objectId} button onClick={onClick}>
                      <ListItemText
                        primary={`Benchmark gestartet am ${date}`}
                        secondary={
                          <span>
                            {answerCount} / {questionCount} Fragen beantwortet
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={onClick}
                        >
                          Fortsetzen
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
});
