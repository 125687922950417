import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Tile } from "./Tile";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

const FIELDS = [
  {
    key: "contact_email",
    label: "Deine Email",
    required: true,
  },
  {
    key: "contact_phone",
    label: "Deine Telefon Nummer",
    required: false,
  },
  {
    key: "contact_company",
    label: "Dein Unternehmen",
    required: false,
  },
  {
    key: "contact_name",
    label: "Dein Name",
    required: false,
  },
  {
    key: "contact_position",
    label: "Deine Position im Unternehmen",
    required: false,
  },
  {
    key: "contact_street",
    label: "Straße und Hausnummer (Unternehmen)",
    required: false,
  },
  {
    key: "contact_post_code",
    label: "Postleitzahl (Unternehmen)",
    required: false,
  },
  {
    key: "contact_city",
    label: "Stadt (Unternhemen)",
    required: false,
  },
];

export const BenchmarkEditFinishRoute = observer(
  function BenchmarkEditFinishRoute() {
    const app = useApp();
    const classes = useStyles();
    const navigate = useNavigate();

    const [emailValid, setEmailValid] = React.useState(() =>
      app.benchmarks.validateCurrentEmail()
    );

    return (
      <Paper className={classes.padding}>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12}>
            <div className={classes.formControl}>
              <Typography component="h1" variant="h5">
                Kontakt Informationen
              </Typography>

              <Typography variant="body1" className={classes.text}>
                Bitte gib deine Email an, damit wir dir den Link zu der
                Auswertung deines Benchmarks zukommen lassen können.
              </Typography>

              <Typography variant="body1" className={classes.text}>
                Alle anderen Felder sind freiwillig, optional können wir dich zu
                deinem Benchmark kontaktieren. [TODO]
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                variant="outlined"
                autoFocus={true}
                error={!emailValid}
                required={true}
                fullWidth
                label={FIELDS[0].label}
                defaultValue={app.benchmarks.benchmark?.contact_email}
                onChange={(e) => {
                  setEmailValid(
                    app.benchmarks.validateCurrentEmail(e.target.value)
                  );
                }}
                onBlur={(e) => {
                  setEmailValid(
                    app.benchmarks.validateCurrentEmail(e.target.value)
                  );

                  app.benchmarks.updateCurrentBenchmark(
                    FIELDS[0].key,
                    e.target.value
                  );
                }}
              />
            </FormControl>

            {FIELDS.filter((field, i) => i !== 0).map((field, i) => (
              <FormControl
                key={field.key}
                variant="outlined"
                className={classes.formControl}
              >
                <TextField
                  variant="outlined"
                  required={field.required}
                  fullWidth
                  label={field.label}
                  defaultValue={app.benchmarks.benchmark?.[field.key] as string}
                  onBlur={(e) => {
                    app.benchmarks.updateCurrentBenchmark(
                      field.key,
                      e.target.value
                    );
                  }}
                />
              </FormControl>
            ))}

            <FormGroup row className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!app.benchmarks.benchmark?.contact_newsletter}
                    onChange={(e) => {
                      app.benchmarks.updateCurrentBenchmark(
                        "contact_newsletter",
                        e.target.checked
                      );
                    }}
                  />
                }
                label="Zum Newsletter anmelden"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!app.benchmarks.benchmark?.contact_get_in_touch}
                    onChange={(e) => {
                      app.benchmarks.updateCurrentBenchmark(
                        "contact_get_in_touch",
                        e.target.checked
                      );
                    }}
                  />
                }
                label="Ich kann bezüglich meines Benchmarks kontaktiert werden"
              />
            </FormGroup>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={!emailValid}
              onClick={() => {
                app.benchmarks.finishCurrentBenchmark().then(() => {
                  navigate("/benchmark/finished");
                });
              }}
            >
              Report anfordern
            </Button>

            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate("/benchmark/edit");
              }}
            >
              Zurück zum Benchmark
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
