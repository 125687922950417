import React, { version } from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { Link } from "react-router-dom";

import styled from "styled-components";

const Footer = styled.div`
  height: 60px;
  background: white;
  border-top: 1px solid #ebebeb;
  user-select: none;

  overflow: hidden;

  position: relative;

  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0; */

  z-index: 3;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const OpenIncLink = styled.a`
  display: block;

  height: 59px;
  line-height: 39px;

  color: #333;
  font-size: 12px;

  text-decoration: none;
  text-transform: uppercase;

  padding: 10px;

  > span {
    display: inline-block;
    height: 39px;
    line-height: 39px;
    vertical-align: top;
  }

  > img {
    display: inline-block;
    height: 39px;
    width: 138px;
    vertical-align: top;
  }
`;

export const FooterContent = observer(function Navigation() {
  const app = useApp();

  return (
    <>
      <Footer>
        <Container>
          <OpenIncLink
            href="https://openinc.de/"
            title="Zur Startseite von open.INC GmbH"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Entwickelt von</span>
            <img
              alt="open.INC GmbH"
              src={require("../assets/logo/openinc.png")}
            />
          </OpenIncLink>
        </Container>
      </Footer>
    </>
  );
});
