import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import ReactPlayer from "react-player";
import { useSprings, animated } from "react-spring";

import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { ErrorMessage } from "./ErrorMessage";
import { Icon } from "@opendash/icons";
import { CategoryAttributes, QuestionAttributes } from "../parse";
import Steps from "rc-steps";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    minHeight: "50vh",
    position: "relative",
  },
  question: {
    position: "absolute",
    top: 0,
    left: 0,

    width: "100%",
    height: "100%",
  },
  questionInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "100%",
    height: "100%",
  },
  questionContent: {
    width: "100%",
  },
  questionName: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  questionDescription: {
    fontSize: "18px",
    marginBottom: theme.spacing(2),
  },
  questionVideo: {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingTop: "56.25%",
  },
  questionVideoPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    marginBottom: theme.spacing(2),
  },
  questionAnswers: {
    "& *": {
      fontSize: "18px !important",
    },
  },
  questionActions: {},
  radioGroup: {},
  radioGroupLabel: {
    display: "block",
    marginBottom: theme.spacing(1),
    marginLeft: 0,
  },
  radioGroupRadio: {
    padding: 0,
    marginRight: 5,
  },
}));

export const BenchmarkEditCategoryQuestions = observer(
  function BenchmarkEditCategoryQuestions({
    category,
    questions,
  }: {
    category: CategoryAttributes;
    questions: QuestionAttributes[];
  }) {
    const app = useApp();
    const navigate = useNavigate();
    const classes = useStyles();

    const [index, setIndex] = React.useState(0);

    const changeQuestion = (questionIndex: number) => {
      setIndex(questionIndex);
      // @ts-ignore
      set((i: number) => {
        return { x: (i - questionIndex) * window.innerWidth };
      });
    };

    const answerResults = questions.map(
      (question) => app.benchmarks.answers[question.objectId]
    );

    const categoryIsFinished = !answerResults
      .map((value) => !!value)
      .includes(false);

    const answerResult =
      Math.floor(
        (answerResults.reduce((acc, v) => acc + v, 0) / answerResults.length) *
          10
      ) / 10;

    const answerResultFloor = ("report_text_" +
      Math.min(4, Math.max(1, Math.floor(answerResult)))) as
      | "report_text_1"
      | "report_text_2"
      | "report_text_3"
      | "report_text_4";

    const answerResultText = category[answerResultFloor] || "";

    const slides = [
      <div className={classes.questionInner}>
        <div className={classes.questionContent}>
          <div className={classes.questionName}>{category.name}</div>
          <div className={classes.questionDescription}>
            <ReactMarkdown children={category.description || ""} />
          </div>
          {category.video_url && (
            <div className={classes.questionVideo}>
              <ReactPlayer
                className={classes.questionVideoPlayer}
                url={category.video_url}
                width="100%"
                height="100%"
              />
            </div>
          )}
          <div className={classes.questionActions}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                changeQuestion(1);
              }}
            >
              Zu den Fragen
            </Button>
          </div>
        </div>
      </div>,
      ...questions.map((question, questionIndex) => {
        const currentValue =
          app.benchmarks.answers[question.objectId]?.toString() || null;
        return (
          <div className={classes.questionInner}>
            <div className={classes.questionContent}>
              <div className={classes.questionName}>{question.name}</div>
              <div className={classes.questionDescription}>
                {question.description}
              </div>

              {question.youtube_url && (
                <div className={classes.questionVideo}>
                  <ReactPlayer
                    className={classes.questionVideoPlayer}
                    url={question.youtube_url}
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
              <div className={classes.questionAnswers}>
                <RadioGroup
                  row={false}
                  className={classes.radioGroup}
                  aria-label={question.name}
                  name={question.objectId}
                  value={currentValue}
                  onChange={(e) => {
                    app.benchmarks
                      .updateAnswerCurrentBenchmark(
                        question.objectId,
                        parseInt(e.target.value) as 1 | 2 | 3 | 4
                      )
                      .then(() => {
                        changeQuestion(questionIndex + 2);
                      });
                  }}
                >
                  <FormControlLabel
                    className={classes.radioGroupLabel}
                    value="1"
                    control={<Radio className={classes.radioGroupRadio} />}
                    label={question.answer_1_text}
                    onClick={() => {
                      if (currentValue === "1") {
                        changeQuestion(questionIndex + 2);
                      }
                    }}
                  />
                  <FormControlLabel
                    className={classes.radioGroupLabel}
                    value="2"
                    control={<Radio className={classes.radioGroupRadio} />}
                    label={question.answer_2_text}
                    onClick={() => {
                      if (currentValue === "2") {
                        changeQuestion(questionIndex + 2);
                      }
                    }}
                  />
                  <FormControlLabel
                    className={classes.radioGroupLabel}
                    value="3"
                    control={<Radio className={classes.radioGroupRadio} />}
                    label={question.answer_3_text}
                    onClick={() => {
                      if (currentValue === "3") {
                        changeQuestion(questionIndex + 2);
                      }
                    }}
                  />
                  <FormControlLabel
                    className={classes.radioGroupLabel}
                    value="4"
                    control={<Radio className={classes.radioGroupRadio} />}
                    label={question.answer_4_text}
                    onClick={() => {
                      if (currentValue === "4") {
                        changeQuestion(questionIndex + 2);
                      }
                    }}
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        );
      }),
      <div className={classes.questionInner}>
        <div className={classes.questionContent}>
          {categoryIsFinished && <p>Alle Fragen beantwortet 🎉</p>}
          {categoryIsFinished && <p>Deine Bewertung: {answerResult}</p>}
          {categoryIsFinished && <ReactMarkdown children={answerResultText} />}
          {!categoryIsFinished && (
            <p>
              Du hast noch nicht alle Fragen beantwortet, willst du zurück gehen
              oder mit der nächsten Kategorie weiter machen?
            </p>
          )}
        </div>
      </div>,
    ];

    const [props, set] = useSprings(slides.length, (i) => ({
      x: i * window.innerWidth,
    }));

    return (
      <div>
        <nav>
          <Steps current={index}>
            <Steps.Step
              key={0}
              title={"Info"}
              icon={<Icon icon="fa:info-circle" />}
              onClick={() => {
                changeQuestion(0);
              }}
              style={{ cursor: "pointer" }}
            />
            {questions.map((question, questionIndex) => (
              <Steps.Step
                key={questionIndex + 1}
                title={question.name_short}
                icon={
                  !!app.benchmarks.answers[question.objectId] ? (
                    <Icon icon="fa:check" />
                  ) : (
                    <Icon icon="fa:question-circle" />
                  )
                }
                onClick={() => {
                  changeQuestion(questionIndex + 1);
                }}
                style={{ cursor: "pointer" }}
              />
            ))}
          </Steps>
        </nav>

        <div className={classes.questionContainer}>
          {slides.map((slide, index) => {
            const { x } = props[index];

            return (
              <animated.div
                key={index}
                className={classes.question}
                style={{
                  transform: x.interpolate((x) => `translate3d(${x}px,0,0)`),
                }}
                children={slide}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
