import { makeAutoObservable } from "mobx";
import { ParseError } from "../helper/ParseError";
import { _UserAttributes } from "../parse";

import type { AppService } from "./AppService";

/**
 * Small API wrapper around Parse Server without using the Parse JS SDK
 */
export class ParseService {
  app: AppService;

  authError: string | null = null;
  user: _UserAttributes | null = null;
  session: string | null = null;

  // private host = "http://localhost:1337/parse";
  private host = "https://parse-fhms-benchmark.apps.openinc.dev/parse";

  private appKey = "benchmark";

  constructor(app: AppService) {
    makeAutoObservable(this);

    this.app = app;
  }

  public async init(cache: any) {
    this.session = cache?.session;

    if (this.session) {
      try {
        const session = await this.get("/sessions/me");

        const user = await this.get(`/users/${session.user.objectId}`);

        this.user = user;
      } catch (error) {}
    }
  }

  public async login(username: string, password: string) {
    if (!username) {
      throw new Error("Bitte Nutzernamen angeben");
    }

    if (!password) {
      throw new Error("Bitte Passwort angeben");
    }

    try {
      const session = await this.post("/login", {
        username,
        password,
      });

      this.session = session.sessionToken;

      const user = await this.get(`/users/${session.objectId}`);

      this.authError = null;

      this.user = user;
    } catch (error) {
      this.session = null;
      this.authError = "Login Error";
      this.user = null;

      throw new Error("Login Fehlgeschlagen");
    }
  }

  public async logout() {
    this.authError = null;
    this.user = null;
    this.session = null;

    this.app.notifications.info("Erfolgreich ausgeloggt.");
  }

  public async fetch(
    path: string,
    info: RequestInit = {},
    errorCount: number = 0
  ): Promise<any> {
    const headers: RequestInit["headers"] = Object.assign(
      {},
      info.headers || {},
      {
        "X-Parse-Application-Id": this.appKey,
        "Content-Type": "application/json",
      },
      this.session
        ? {
            "X-Parse-Session-Token": this.session,
          }
        : {}
    );

    const response = await fetch(this.host + path, {
      ...info,
      headers,
    });

    if (response.status >= 400) {
      const json = await response.json();

      if (json.code && json.error) {
        if (json.code === 209 && json.error === "Invalid session token") {
          this.user = null;
          this.session = null;

          if (errorCount === 0) {
            this.app.notifications.info(
              "Deine Session ist abgelaufen, bitte melde dich erneut an."
            );

            return this.fetch(path, info, errorCount + 1);
          }
        }

        throw new ParseError(json.code, json.error);
      } else {
        throw new Error("Bad Status Code");
      }
    }

    return await response.json();
  }

  public async get(path: string) {
    return this.fetch(path, {
      method: "GET",
    });
  }

  public async cc<T = any>(fn: string, data: any = {}): Promise<T> {
    return this.post("/functions/" + fn, data);
  }

  public async post(path: string, data: any) {
    return this.fetch(path, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  public async put(path: string, data: any) {
    return this.fetch(path, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  public async delete(path: string) {
    return this.fetch(path, {
      method: "DELETE",
    });
  }
}
