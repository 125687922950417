import { makeAutoObservable } from "mobx";

import { SnackbarMessage, OptionsObject } from "notistack";

import type { AppService } from "./AppService";

export class NotificationService {
  private app: AppService;

  notifications: { message: SnackbarMessage; options: OptionsObject }[] = [];

  constructor(app: AppService) {
    makeAutoObservable(this);

    this.app = app;
  }

  public async init(cache: any) {
    // Object.assign(this, cache);
  }

  success(message: SnackbarMessage) {
    this.notifications.push({ message, options: { variant: "success" } });
  }

  error(message: SnackbarMessage) {
    this.notifications.push({ message, options: { variant: "error" } });
  }

  warning(message: SnackbarMessage) {
    this.notifications.push({ message, options: { variant: "warning" } });
  }

  info(message: SnackbarMessage) {
    this.notifications.push({ message, options: { variant: "info" } });
  }

  toJSON() {
    return undefined;
  }
}
