import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  downloadButton: {
    // float: "right",
  },
}));

export const AdminBenchmarkRoute = observer(function AdminBenchmarkRoute() {
  const app = useApp();
  const classes = useStyles();

  // TODO: Check auth/permission

  return (
    <div className={classes.padding}>
      <Typography variant="h5" component="h2" className={classes.marginBottom}>
        Benchmarks
      </Typography>
      <Typography variant="body1" className={classes.marginBottom}>
        Hier klicken, um alle beendeten Benchmarks zu exportieren.
      </Typography>
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.downloadButton}
        onClick={() => {
          app.admin.downloadBenchmarks();
        }}
      >
        Herunterladen
      </Button>
    </div>
  );
});
