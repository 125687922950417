import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminCategoryRoute = observer(function AdminCategoryRoute() {
  const app = useApp();

  return (
    <AdminList
      title="Kategorien"
      className="Category"
      navName="category"
      rows={app.benchmarks.categories}
    />
  );
});
