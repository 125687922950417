import * as React from "react";

import styled from "styled-components";

import { Icon } from "@opendash/icons";

const Container = styled.div`
  width: 100%;
  height: 200px;

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const IconHolder = styled.div`
  font-size: 2em;
  color: #4caf50;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`;

const Message = styled.div`
  margin-bottom: 10px;
`;

export function SuccessMessage({
  icon,
  title,
  message,
}: {
  icon: string;
  title: string;
  message: string;
}) {
  return (
    <Container>
      <div>
        <IconHolder>
          <Icon icon={icon} />
        </IconHolder>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </div>
    </Container>
  );
}
