import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";

import { useApp } from "../context";

export const AdminRegionEditRoute = observer(function AdminRegionEditRoute() {
  const app = useApp();

  const { id } = useParams();

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Name"
        defaultValue={
          app.benchmarks.regions.find((o) => o.objectId === id)?.name || ""
        }
        onBlur={(e) => {
          app.admin.updateRegion(id, "name", e.target.value);
        }}
      />
    </>
  );
});
