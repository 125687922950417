import { makeAutoObservable } from "mobx";

import type { AppService } from "./AppService";

import { ReportResponse } from "../interfaces/ReportResponse";

import type { Options as HighchartsOptions } from "highcharts/highstock";

export class ReportService {
  app: AppService;

  loading: boolean = true;
  report: ReportResponse | null = null;
  error: Error | null = null;

  lastReport: { id: string; secret: string } | null = null;

  constructor(app: AppService) {
    makeAutoObservable(this);

    this.app = app;
  }

  public async init(cache: any) {
    Object.assign(this, cache);
  }

  public get reportUrl(): string {
    if (!this.lastReport) {
      return "";
    }

    const host = window.location.origin;
    const id = this.lastReport.id;
    const secret = this.lastReport.secret;

    return `${host}/report/${id}/${secret}`;
  }

  public get categoryMap(): Record<string, string> {
    return {
      all: "Alle Kategorien",
      ...Object.fromEntries(
        this.app.benchmarks.categories
          .filter((c) => this.report?.categoriesCurrent[c.objectId])
          .map((c) => [c.objectId, c.name])
      ),
    };
  }

  private getData(category: string): number[] {
    if (category === "all") {
      return Object.keys(this.report?.categoriesCurrent || {}).map(
        (c) => this.report?.categoriesCurrent[c] || 0
      );
    } else {
      return this.app.benchmarks.questions
        .filter((q) => q.category?.objectId === category)
        .map((q) => this.report?.questionsCurrent[q.objectId] || 0);
    }
  }

  private getDataComparing(category: string): number[] {
    if (category === "all") {
      return Object.keys(this.report?.categoriesCurrent || {}).map(
        (c) => this.report?.categoriesComparing[c] || 0
      );
    } else {
      return this.app.benchmarks.questions
        .filter((q) => q.category?.objectId === category)
        .map((q) => this.report?.questionsComparing[q.objectId] || 0);
    }
  }

  private getLabels(category: string): string[] {
    if (category === "all") {
      return Object.keys(this.report?.categoriesCurrent || {}).map((c) => {
        return this.categoryMap[c];
      });
    } else {
      return this.app.benchmarks.questions
        .filter((q) => q.category?.objectId === category)
        .map((q) => q.name_short || "");
    }
  }

  public getChart(
    category: string,
    colorA: string,
    colorB: string
  ): HighchartsOptions {
    const labels = this.getLabels(category);

    return {
      chart: {
        polar: true,
      },

      title: {
        text: undefined,
      },

      subtitle: {
        text: undefined,
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
      },

      xAxis: {
        // tickInterval: 1,
        // min: 0,
        // max: labels.length,
        // labels: {
        //   formatter: function () {
        //     return labels[this.value] || "";
        //   },
        // },

        categories: labels,
        // gridLineWidth: 3,
        // gridLineColor: "#fff",
        gridLineWidth: 1,
        gridZIndex: 20,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 4,
        tickInterval: 1,
        gridLineWidth: 1,
        gridZIndex: 20,
        labels: {
          formatter: () => "",
        },
      },

      plotOptions: {
        series: {
          pointStart: 0,
          pointInterval: 1,
        },
        column: {
          pointPadding: 0,
          groupPadding: 0,
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },

      tooltip: {
        shared: true,
      },

      series: [
        {
          name: "Dein Antworten",
          data: this.getData(category),
          type: "scatter",
          // pointPlacement: "between",
          color: colorB,
          pointPadding: 0.2,
          pointPlacement: 0,
          // opacity: 0.6,
          zIndex: 30,

          marker: {
            radius: 5,
          },

          borderColor: "#fff",
          borderWidth: 2,
        },
        {
          name: "Durchschnittliche Antworten",
          data: this.getDataComparing(category),
          type: "column",
          // pointPlacement: "between",
          color: colorA,
          pointPadding: 0,
          pointPlacement: 0,
          // opacity: 0.8,
        },
      ],
    };
  }

  public async fetchReport(id: string, secret: string) {
    try {
      this.loading = true;

      const { result } = await this.app.parse.cc("report-fetch", {
        id: id,
        secret: secret,
      });

      this.lastReport = { id, secret };

      this.report = result;
      this.error = null;
      this.loading = false;

      this.app.notifications.success("Report geladen");
    } catch (error) {
      this.report = null;
      this.error = error;
      this.loading = false;
    }
  }
}
