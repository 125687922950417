import React from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { useSnackbar } from "notistack";

import {
  createMuiTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#73cea7",
      contrastText: "#fff",
    },
    secondary: {
      main: "#57c2e0",
    },
  },
});

export const NotificationProvider = observer(function NotificationProvider({
  children,
}) {
  const app = useApp();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    autorun(() => {
      while (app.notifications.notifications.length > 0) {
        const notification = app.notifications.notifications.shift();

        if (notification) {
          enqueueSnackbar(notification.message, notification.options);
        }
      }
    });
  }, []);

  return null;
});
