import React from "react";

import { Grid, MenuItem, MenuList, Paper } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const LINKS = [
  {
    url: "/admin/users",
    label: "Benutzer",
  },
  {
    url: "/admin/benchmarks",
    label: "Benchmarks",
  },
  {
    url: "/admin/contacts",
    label: "Kontakte",
  },
  {
    url: "/admin/question",
    label: "Fragen",
  },
  {
    url: "/admin/category",
    label: "Kategorien",
  },
  {
    url: "/admin/industry",
    label: "Branchen",
  },
  {
    url: "/admin/region",
    label: "Regionen",
  },
];

export const AdminHomeLayout = React.memo(function AdminHomeLayout({
  children,
}) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Paper>
          <MenuList>
            {LINKS.map(({ url, label }) => (
              <MenuItem
                key={url}
                button
                selected={window.location.pathname.startsWith(url)}
                onClick={() => {
                  navigate(url);
                }}
              >
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Grid>
      <Grid item md={8} xs={12}>
        <Paper>{children}</Paper>
      </Grid>
    </Grid>
  );
});
