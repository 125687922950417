import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";

export const NotFoundRoute = observer(function NotFoundRoute() {
  return (
    <ErrorMessage
      icon="fa:exclamation-circle"
      title="404"
      message="Die Seite konnte nicht gefunden werden"
    />
  );
});
