import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useOutlet } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { useApp } from "../context";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  createButton: {
    float: "right",
  },
  listItem: {
    paddingRight: 220,
  },
}));

interface Props {
  title: string;
  className: string;
  navName: string;
  rows: { objectId: string; name: string }[];
}

export const AdminList = observer<Props>(function AdminList({
  title,
  className,
  navName,
  rows,
}) {
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const outlet = useOutlet();

  // TODO: Check auth/permission

  const onCreate = () => {
    app.admin.create(className).then((id) => {
      navigate(`/admin/${navName}/edit/${id}`);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h2" className={classes.padding}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={onCreate}
            className={classes.createButton}
          >
            Neu
          </Button>
          {title} Verwaltung
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List>
          {rows.map((o) => {
            const onEdit = () => {
              navigate(`/admin/${navName}/edit/${o.objectId}`);
            };

            const onDelete = () => {
              app.admin.delete(className, o.objectId);
            };

            return (
              <ListItem
                key={o.objectId}
                button
                onClick={onEdit}
                className={classes.listItem}
              >
                <ListItemText primary={o.name} />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={onEdit}
                  >
                    Bearbeiten
                  </Button>{" "}
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={onDelete}
                  >
                    Löschen
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>

      <Dialog open={!!outlet} onClose={() => navigate(`/admin/${navName}`)}>
        <DialogTitle>{title} bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: 30 }}>
            Änderungen werden sofort übernommen und müssen nicht extra
            gespeichert werden.
          </DialogContentText>

          {outlet}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate(`/admin/${navName}`);
            }}
            color="primary"
          >
            Fertig
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
});
