import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { useParams } from "react-router-dom";
import {
  makeStyles,
  CircularProgress,
  Paper,
  useTheme,
  MenuList,
  MenuItem,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";

import { Highcharts } from "../helper/Highcharts";

import HighchartsReact from "highcharts-react-official";

import { ErrorMessage } from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  loading: {
    textAlign: "center",
    padding: "50px 0",
  },
  textbox: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export const ReportRoute = observer(function BenchmarkReportRoute() {
  const app = useApp();
  const theme = useTheme();
  const classes = useStyles();
  const { id, secret } = useParams();

  const [category, setCategory] = React.useState("all");

  React.useEffect(() => {
    app.report.fetchReport(id, secret);
  }, [id, secret]);

  if (app.report.error) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Unbekannter Fehler"
        message="Der Report konnte nicht geladen werden."
      />
    );
  }

  if (app.report.loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (!app.report.report) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Fehler"
        message="Es wurde kein Report geladen."
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Paper>
          <MenuList>
            {Object.entries(app.report.categoryMap).map(([key, name]) => (
              <MenuItem
                key={key}
                button
                selected={category === key}
                onClick={() => {
                  setCategory(key);
                }}
              >
                {name}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Grid>
      <Grid item md={8} xs={12}>
        <Paper>
          <div className={classes.textbox}>
            <Typography variant="h5" component="h2">
              {app.report.categoryMap[category]}
            </Typography>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren.
            </Typography>
          </div>

          <HighchartsReact
            highcharts={Highcharts}
            options={app.report.getChart(
              category,
              theme.palette.primary.main,
              theme.palette.secondary.main
            )}
          />

          <div className={classes.textbox}>
            {app.benchmarks.questions
              .filter((q) => q.category?.objectId === category)
              .map((q) => (
                <Typography key={q.objectId} variant="body1">
                  <b>Auswertung zu {q.name}: </b>
                  {app.report.report?.questionsCurrentText[q.objectId]}
                </Typography>
              ))}
          </div>

          {/* <pre>{JSON.stringify(app.report.report, null, 2)}</pre> */}
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.marginBottom}>
        <Paper className={classes.padding}>
          <Typography variant="body1" className={classes.marginBottom}>
            Du kannst den folgenden Link einem Kollegen geben, mit dem du den
            Benchmark Report teilen möchtest.
          </Typography>
          <TextField
            value={app.report.reportUrl}
            label="Report teilen"
            variant="outlined"
            fullWidth
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
});
