import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  createMuiTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#73cea7",
      contrastText: "#fff",
    },
    secondary: {
      main: "#57c2e0",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
});

export const ThemeProvider = observer(function ThemeProvider({ children }) {
  const app = useApp();

  return <MaterialThemeProvider theme={theme} children={children} />;
});
