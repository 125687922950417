import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Routes, Route, Navigate } from "react-router-dom";

import { BenchmarkHomeRoute } from "./BenchmarkHomeRoute";
import { BenchmarkResumeRoute } from "./BenchmarkResumeRoute";

import { BenchmarkEditRoute } from "./BenchmarkEditRoute";
import { BenchmarkEditOverviewRoute } from "./BenchmarkEditOverviewRoute";
import { BenchmarkEditMetaRoute } from "./BenchmarkEditMetaRoute";
import { BenchmarkEditFinishRoute } from "./BenchmarkEditFinishRoute";
import { BenchmarkEditCategoryRoute } from "./BenchmarkEditCategoryRoute";

import { ReportRoute } from "./ReportRoute";

import { AdminHomeRoute } from "./AdminHomeRoute";
import { AdminBenchmarkRoute } from "./AdminBenchmarkRoute";
import { AdminContactsRoute } from "./AdminContactsRoute";
import { AdminUserRoute } from "./AdminUserRoute";
import { AdminQuestionRoute } from "./AdminQuestionRoute";
import { AdminQuestionEditRoute } from "./AdminQuestionEditRoute";
import { AdminCategoryRoute } from "./AdminCategoryRoute";
import { AdminCategoryEditRoute } from "./AdminCategoryEditRoute";
import { AdminIndustryRoute } from "./AdminIndustryRoute";
import { AdminIndustryEditRoute } from "./AdminIndustryEditRoute";
import { AdminRegionRoute } from "./AdminRegionRoute";
import { AdminRegionEditRoute } from "./AdminRegionEditRoute";

import { LoginRoute } from "./LoginRoute";
import { LogoutRoute } from "./LogoutRoute";

import { NotFoundRoute } from "./NotFoundRoute";
import { AdminUserEditRoute } from "./AdminUserEditRoute";
import { BenchmarkCompletedRoute } from "./BenchmarkCompletedRoute";

export const Router = observer(function Router() {
  const app = useApp();

  return (
    <Routes>
      <Route path="" element={<BenchmarkHomeRoute />} />
      <Route path="login" element={<LoginRoute />} />
      <Route path="logout" element={<LogoutRoute />} />

      <Route path="benchmark" element={<BenchmarkHomeRoute />} />
      <Route path="benchmark/finished" element={<BenchmarkCompletedRoute />} />
      <Route path="benchmark/edit" element={<BenchmarkEditRoute />}>
        <Route path="" element={<BenchmarkEditOverviewRoute />} />
        <Route path="meta" element={<BenchmarkEditMetaRoute />} />
        <Route path="finish" element={<BenchmarkEditFinishRoute />} />
        <Route
          path="category/:categoryId"
          element={<BenchmarkEditCategoryRoute />}
        />
      </Route>

      <Route
        path="benchmark/resume/:id/:secret"
        element={<BenchmarkResumeRoute />}
      />

      <Route path="report/:id/:secret" element={<ReportRoute />} />

      <Route path="admin" element={<AdminHomeRoute />}>
        <Route path="" element={<Navigate to="/admin/users" />} />
        <Route path="benchmarks" element={<AdminBenchmarkRoute />} />
        <Route path="contacts" element={<AdminContactsRoute />} />
        <Route path="users" element={<AdminUserRoute />}>
          <Route path="edit/:id" element={<AdminUserEditRoute />} />
        </Route>
        <Route path="question" element={<AdminQuestionRoute />}>
          <Route path="edit/:id" element={<AdminQuestionEditRoute />} />
        </Route>
        <Route path="category" element={<AdminCategoryRoute />}>
          <Route path="edit/:id" element={<AdminCategoryEditRoute />} />
        </Route>
        <Route path="industry" element={<AdminIndustryRoute />}>
          <Route path="edit/:id" element={<AdminIndustryEditRoute />} />
        </Route>
        <Route path="region" element={<AdminRegionRoute />}>
          <Route path="edit/:id" element={<AdminRegionEditRoute />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFoundRoute />} />
    </Routes>
  );
});
