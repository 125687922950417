import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";

import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";

const FIELDS = [
  {
    label: "Name",
    key: "name",
    multi: false,
  },
  {
    label: "Reihenfolge",
    key: "order",
    multi: false,
    number: true,
  },
  {
    label: "Beschreibung",
    key: "description",
    multi: true,
  },
  {
    label: "YouTube Link",
    key: "video_url",
    multi: false,
  },
  {
    label: "Report für 1 Punkt",
    key: "report_text_1",
    multi: true,
  },
  {
    label: "Report für 2 Punkt",
    key: "report_text_2",
    multi: true,
  },
  {
    label: "Report für 3 Punkt",
    key: "report_text_3",
    multi: true,
  },
  {
    label: "Report für 4 Punkt",
    key: "report_text_4",
    multi: true,
  },
];

export const AdminCategoryEditRoute = observer(
  function AdminCategoryEditRoute() {
    const app = useApp();

    const { id } = useParams();

    const category = app.benchmarks.categories.find((o) => o.objectId === id);

    if (!category) {
      return (
        <ErrorMessage
          icon="fa:exclamation-circle"
          title="Kategorie nicht gefunden"
          message="Bitte den Bearbeiten Dialog neu öffnen."
        />
      );
    }

    return (
      <>
        {FIELDS.map((field) => (
          <TextField
            key={field.key}
            type={field.number ? "number" : undefined}
            variant="outlined"
            margin="normal"
            fullWidth
            multiline={field.multi}
            label={field.label}
            // @ts-ignore
            defaultValue={category[field.key] || ""}
            onBlur={(e) => {
              if (field.number) {
                app.admin.updateCategory(id, {
                  [field.key]: parseInt(e.target.value),
                });
              } else {
                app.admin.updateCategory(id, { [field.key]: e.target.value });
              }
            }}
          />
        ))}
      </>
    );
  }
);
