import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Tile } from "./Tile";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const BenchmarkEditMetaRoute = observer(
  function BenchmarkEditMetaRoute() {
    const app = useApp();
    const classes = useStyles();
    const navigate = useNavigate();

    return (
      <Paper className={classes.padding}>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12}>
            <div className={classes.formControl}>
              <Typography component="h1" variant="h5">
                Informationen zu deinem Unternehmen
              </Typography>

              <Typography variant="body1">
                Bitte beantworte folgende Fragen über dein Unternehmen, bevor Du
                mit dem Benchmark beginnen.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.formControl}>
              In welcher/welchen Branche/n ist dein Unternehmen vordergründig
              tätig?
            </Typography>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Branche</InputLabel>
              <Select
                value={app.benchmarks.benchmark?.industry?.objectId || ""}
                onChange={(e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "industry",
                    e.target.value as string
                  );
                }}
                label="Branche"
              >
                {Object.entries(app.benchmarks.industriesOptions).map(
                  ([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {key === "" ? <em>{label}</em> : label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Typography variant="body1" className={classes.formControl}>
              Wo hat dein Unternehmen seinen Sitz?
            </Typography>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Unternehmenssitz</InputLabel>
              <Select
                value={app.benchmarks.benchmark?.region?.objectId || ""}
                onChange={(e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "region",
                    e.target.value as string
                  );
                }}
                label="Unternehmenssitz"
              >
                {Object.entries(app.benchmarks.regionsOptions).map(
                  ([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {key === "" ? <em>{label}</em> : label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Typography variant="body1" className={classes.formControl}>
              Wie viele festangestellte Mitarbeiter sind deiner Kenntnis nach in
              deinem Unternehmen tätig? (Stand heute)
            </Typography>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Mitarbeiter</InputLabel>
              <Select
                value={app.benchmarks.benchmark?.company_size || ""}
                onChange={(e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "company_size",
                    e.target.value as string
                  );
                }}
                label="Mitarbeiter"
              >
                {Object.entries(app.benchmarks.sizeOptions).map(
                  ([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {key === "" ? <em>{label}</em> : label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Typography variant="body1" className={classes.formControl}>
              Wie hoch war der Umsatz deines Unternehmens deiner Kenntnis nach
              im letzten Wirtschaftsjahr?
            </Typography>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Umsatz</InputLabel>
              <Select
                value={app.benchmarks.benchmark?.company_revenue || ""}
                onChange={(e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "company_revenue",
                    e.target.value as string
                  );
                }}
                label="Umsatz"
              >
                {Object.entries(app.benchmarks.revenueOptions).map(
                  ([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {key === "" ? <em>{label}</em> : label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            {Object.keys(app.benchmarks.answers).length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/benchmark/edit");
                }}
              >
                Zurück zur Übersicht
              </Button>
            )}

            {Object.keys(app.benchmarks.answers).length === 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(
                    `/benchmark/edit/category/${app.benchmarks.categories[0]?.objectId}`
                  );
                }}
              >
                Jetzt den Benchmark starten
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
