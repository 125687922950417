import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const AppLoadingScreen = observer(function AppLoadingScreen({
  children,
}) {
  const app = useApp();

  if (app.loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return <>{children}</>;
});
