import { makeAutoObservable } from "mobx";

import type { AppService } from "./AppService";

import type {
  BenchmarkAttributes,
  CategoryAttributes,
  QuestionAttributes,
  RegionAttributes,
  IndustryAttributes,
} from "../parse";

export class BenchmarkService {
  app: AppService;

  currentBenchmark: string | null = null;
  benchmarks: Record<string, BenchmarkAttributes> = {};

  categories: CategoryAttributes[] = [];
  questions: QuestionAttributes[] = [];
  regions: RegionAttributes[] = [];
  industries: IndustryAttributes[] = [];

  constructor(app: AppService) {
    makeAutoObservable(this);

    this.app = app;
  }

  public get benchmark(): BenchmarkAttributes | null {
    if (!this.currentBenchmark) {
      return null;
    }

    return this.benchmarks[this.currentBenchmark];
  }

  public get benchmarkUrl(): string {
    if (!this.benchmark) {
      return "";
    }

    const host = window.location.origin;
    const id = this.benchmark.objectId;
    const secret = this.benchmark.secret;

    return `${host}/benchmark/resume/${id}/${secret}`;
  }

  public get metaAsText() {
    if (!this.benchmark) {
      return "";
    }

    return [
      this.industriesOptions[this.benchmark.industry?.objectId || ""],
      this.regionsOptions[this.benchmark.region?.objectId || ""],
      this.sizeOptions[this.benchmark.company_size || ""],
      this.revenueOptions[this.benchmark.company_revenue || ""],
    ].join(", ");
  }

  public get industriesOptions() {
    return {
      "": "Bitte Branche ausfüllen",
      ...Object.fromEntries(this.industries.map((x) => [x.objectId, x.name])),
      other: "Sonstiges",
    };
  }

  public get regionsOptions() {
    return {
      "": "Bitte Region ausfüllen",
      ...Object.fromEntries(this.regions.map((x) => [x.objectId, x.name])),
      // other: "Sonstiges",
    };
  }

  public get sizeOptions() {
    return {
      "": "Bitte Mitarbeiter ausfüllen",
      "<9": "0 - 9 Mitarbeiter",
      "10 - 49": "10 - 49 Mitarbeiter",
      "50-249": "50-249 Mitarbeiter",
      ">249": "Mehr als 249 Mitarbeiter",
    };
  }

  public get revenueOptions() {
    return {
      "": "Bitte Umsatz ausfüllen",
      "<1000000": "Weniger als 1 Mio. € Umsatz",
      "1000000-10000000": "1 Mio. € - 10 Mio. € Umsatz",
      "10000000-50000000": "10 Mio. € - 50 Mio. € Umsatz",
      ">50000000": "Mehr als 50 Mio. € Umsatz",
    };
  }

  public get answers(): Record<string, 1 | 2 | 3 | 4> {
    if (!this.benchmark?.answers) {
      return {};
    }

    return this.benchmark.answers;
  }

  public validateCurrentBenchmark(): { valid: boolean; field?: string } {
    if (!this.benchmark) {
      return {
        valid: false,
        field: "benchmark",
      };
    }

    if (!this.benchmark.industry) {
      return {
        valid: false,
        field: "meta",
      };
    }

    if (!this.benchmark.region) {
      return {
        valid: false,
        field: "meta",
      };
    }

    if (!this.benchmark.company_revenue) {
      return {
        valid: false,
        field: "meta",
      };
    }

    if (!this.benchmark.company_size) {
      return {
        valid: false,
        field: "meta",
      };
    }

    const answers = Object.values(this.answers).filter(Boolean).length;

    if (answers < this.questions.length) {
      return {
        valid: false,
        field: "answers",
      };
    }

    return {
      valid: true,
    };
  }

  public validateCurrentEmail(email?: string): boolean {
    email = email ?? this.benchmark?.contact_email;

    return (
      !!email &&
      email.includes("@") &&
      !email.startsWith("@") &&
      !email.endsWith("@")
    );
  }

  public async init(cache: any) {
    Object.assign(this, cache);

    const categories: CategoryAttributes[] = (
      await this.app.parse.get("/classes/Category?order=order")
    ).results;

    const questions: QuestionAttributes[] = (
      await this.app.parse.get("/classes/Question?order=order")
    ).results;

    const industries: IndustryAttributes[] = (
      await this.app.parse.get("/classes/Industry?order=order")
    ).results;

    const regions: RegionAttributes[] = (
      await this.app.parse.get("/classes/Region?order=order")
    ).results;

    if (this.app.admin.isAdmin) {
      this.categories = categories;
      this.questions = questions;
    } else {
      // Remove all categories without a question
      this.categories = categories.filter(
        (category) =>
          !!questions.find(
            (question) => question.category?.objectId === category.objectId
          )
      );

      // Remove all questions without a category
      this.questions = questions.filter(
        (question) =>
          question.category?.objectId &&
          !!categories.find(
            (category) => category.objectId === question.category?.objectId
          )
      );
    }

    this.industries = industries;
    this.regions = regions;
  }

  public async resumeBenchmark(id: string, secret: string) {
    try {
      const { result: benchmark } = await this.app.parse.cc("benchmark-fetch", {
        id: id,
        secret: secret,
      });

      this.benchmarks[benchmark.objectId] = benchmark;

      this.currentBenchmark = benchmark.objectId;

      this.app.notifications.success("Benchmark geladen");
    } catch (error) {
      this.app.notifications.error(
        "Benchmark wurde nicht gefunden oder der Zugang ist abgelaufen"
      );
    }
  }

  public async createBenchmark() {
    try {
      const { result: benchmark } = await this.app.parse.cc("benchmark-create");

      this.benchmarks[benchmark.objectId] = benchmark;

      this.currentBenchmark = benchmark.objectId;

      this.app.notifications.success("Benchmark erstellt");
    } catch (error) {
      this.app.notifications.error("Benchmark konnte nicht erstellt werden");
    }
  }

  public async fetchCurrentBenchmark() {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-fetch", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
      });

      Object.assign(this.benchmark, result);

      this.app.notifications.success("Benchmark synchronisiert");
    } catch (error) {
      this.app.notifications.error(
        "Benchmark konnte nicht synchronisiert werden"
      );
    }
  }

  public async updateCurrentBenchmark(field: string, value: string | boolean) {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-update", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
        field,
        value,
      });

      const changed = result[field] != (this.benchmark?.[field] as any);

      Object.assign(this.benchmark, result);

      if (changed) {
        this.app.notifications.success("Eingabe gespeichert");
      }
    } catch (error) {
      this.app.notifications.error("Eingabe konnte nicht gespeichert werden");
    }
  }

  public async updateAnswerCurrentBenchmark(
    questionId: string,
    value: 1 | 2 | 3 | 4
  ) {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-update", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
        field: "answers",
        key: questionId,
        value,
      });

      Object.assign(this.benchmark, result);

      this.app.notifications.success("Antwort gespeichert");
    } catch (error) {
      this.app.notifications.error("Antworte konnte nicht gespeichert werden");
    }
  }

  public async finishCurrentBenchmark() {
    try {
      if (!this.benchmark) {
        return;
      }

      const id = this.benchmark.objectId;

      const { result } = await this.app.parse.cc("benchmark-finish", {
        id: id,
        secret: this.benchmark.secret,
      });

      this.currentBenchmark = null;
      delete this.benchmarks[id];

      this.app.notifications.success("Benchmark abgeschlossen");
    } catch (error) {
      this.app.notifications.error("Benchmark kann nicht abgeschlossen werden");
    }
  }
}
