import { makeAutoObservable } from "mobx";

import type { AppService } from "./AppService";

export class UiService {
  app: AppService;

  notifications: string[] = [];

  constructor(app: AppService) {
    makeAutoObservable(this);

    this.app = app;
  }

  public async init(cache: any) {
    Object.assign(this, cache);
  }

  notification(message: string) {}
}
