import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { Button, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export const LoginRoute = observer(function LoginRoute() {
  const app = useApp();
  const navigate = useNavigate();

  const [state, setState] = React.useState({ email: "", password: "" });

  if (app.parse.user) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Bereits Eingeloggt"
        message="..."
      />
    );
  }

  return (
    <div style={{ width: 400, margin: "0 auto" }}>
      <Typography component="h1" variant="h5" align="center">
        Admin Login
      </Typography>
      <form
        noValidate
        onSubmit={function handleSubmit(event) {
          event.preventDefault();

          app.parse.login(state.email, state.password).then(
            () => {
              navigate("/admin");
            },
            (error) => {
              app.notifications.error(error.message);
            }
          );
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => {
            setState((cur) => ({ ...cur, email: e.target.value }));
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => {
            setState((cur) => ({ ...cur, password: e.target.value }));
          }}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Anmelden
        </Button>
      </form>
    </div>
  );
});
