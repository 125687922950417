import * as React from "react";

import { AppContext } from "./index";
import { AppService } from "../services/AppService";

export const AppContextProvider = React.memo(({ children }) => {
  const app = React.useMemo(() => new AppService(), []);

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
});
