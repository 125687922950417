import React from "react";

import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  validationMessage: {
    border: `1px solid ${theme.palette.error.dark}`,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
  editMetaButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const BenchmarkEditOverviewRoute = observer(
  function BenchmarkEditOverviewRoute() {
    const app = useApp();
    const classes = useStyles();
    const navigate = useNavigate();

    const validation = app.benchmarks.validateCurrentBenchmark();

    return (
      <Grid container justify="center" spacing={1}>
        <Grid item xs={12} className={classes.marginBottom}>
          <Typography
            variant="h4"
            component="h1"
            className={classes.marginBottom}
          >
            Deine Angaben zum Thema Digitalisierung
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.marginBottom}>
          <Grid container justify="center" spacing={2}>
            {app.benchmarks.categories.map((category) => {
              const questions = app.benchmarks.questions.filter(
                (q) => q.category?.objectId === category.objectId
              );

              const questionIds = questions.map((q) => q.objectId);

              const answers = Object.entries(app.benchmarks.answers).filter(
                ([questionId, value]) =>
                  value && questionIds.includes(questionId)
              );

              return (
                <Grid key={category.objectId} item xs={12} sm={4}>
                  <Card style={{ height: "100%" }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {category.name}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={(answers.length / questions.length) * 100}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {answers.length} / {questions.length} Fragen beantwortet
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          navigate(
                            `/benchmark/edit/category/${category.objectId}`
                          );
                        }}
                      >
                        {answers.length === 0
                          ? "Starten"
                          : answers.length === questions.length
                          ? "Überarbeiten"
                          : "Fortsetzen"}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.marginBottom}>
          <Paper className={classes.padding}>
            <Typography variant="body1" className={classes.marginBottom}>
              Digitalisierung ist Teamarbeit - Binde deine Kolleginnen und
              Kollegen beim Ausfüllen des Benchmark gerne mit ein. Sende denen
              dazu einfach diesen Link:
            </Typography>
            <TextField
              value={app.benchmarks.benchmarkUrl}
              label="Benchmark teilen"
              variant="outlined"
              fullWidth
              onFocus={(e) => {
                e.target.select();
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.marginBottom}>
          <Paper className={classes.padding}>
            <Typography variant="body1">
              {app.benchmarks.metaAsText}{" "}
              <Button
                // variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  navigate("/benchmark/edit/meta");
                }}
                className={classes.editMetaButton}
              >
                Bearbeiten
              </Button>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.marginBottom}>
          <Paper className={classes.padding}>
            {!validation.valid && validation.field === "answers" && (
              <Typography variant="body1" className={classes.validationMessage}>
                Bitte beantworte alle Fragen, bevor Du das Benchmark abschließt.
              </Typography>
            )}
            {!validation.valid && validation.field === "meta" && (
              <Typography variant="body1" className={classes.validationMessage}>
                Bitte fülle die Informationen über dein Unternhemen aus, bevor
                Du das Benchmark abschließt.
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={!validation.valid}
              onClick={() => {
                navigate("/benchmark/edit/finish");
              }}
            >
              Benchmark abschließen
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
);
