import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminUserRoute = observer(function AdminUserRoute() {
  const app = useApp();

  return (
    <AdminList
      title="Administratoren"
      className="_User"
      navName="users"
      rows={app.admin.users}
    />
  );
});
