import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminQuestionRoute = observer(function AdminQuestionRoute() {
  const app = useApp();

  return (
    <AdminList
      title="Fragen"
      className="Question"
      navName="question"
      rows={app.benchmarks.questions}
    />
  );
});
