import { makeAutoObservable } from "mobx";
import {
  BenchmarkAttributes,
  CategoryAttributes,
  IndustryAttributes,
  QuestionAttributes,
  RegionAttributes,
  _UserAttributes,
} from "../parse";

import type { AppService } from "./AppService";

export class AdminService {
  app: AppService;

  users: _UserAttributes[] = [];

  constructor(app: AppService) {
    makeAutoObservable(this);

    this.app = app;
  }

  public get isAdmin() {
    return !!this.app.parse.user?.isAdmin;
  }

  public async init(cache: any = {}) {
    Object.assign(this, cache);

    if (!this.isAdmin) {
      return;
    }

    this.users = (
      await this.app.parse.get("/classes/_User?order=name")
    ).results;
  }

  private updateHasChanges(a: any, b: any) {
    for (const [key, value] of Object.entries(b)) {
      if (a[key] !== value) {
        return true;
      }
    }

    return false;
  }

  public async updateUser(id: string, data: Partial<_UserAttributes>) {
    const user = this.users.find((u) => u.objectId === id);

    if (!user || !this.updateHasChanges(user, data)) {
      return;
    }

    if ("password" in data && !data.password) {
      return;
    }

    await this.app.parse.put(`/classes/_User/${id}`, data);

    Object.assign(user, await this.app.parse.get(`/classes/_User/${id}`));

    this.app.notifications.success("Wert wurde geändert");
  }

  public async updateQuestion(id: string, data: Partial<QuestionAttributes>) {
    const question = this.app.benchmarks.questions.find(
      (question) => question.objectId === id
    );

    if (!question || !this.updateHasChanges(question, data)) {
      return;
    }

    await this.app.parse.put(`/classes/Question/${id}`, data);

    Object.assign(question, data);

    this.app.notifications.success("Wert wurde geändert");
  }

  public async updateCategory(id: string, data: Partial<CategoryAttributes>) {
    const category = this.app.benchmarks.categories.find(
      (category) => category.objectId === id
    );

    if (!category || !this.updateHasChanges(category, data)) {
      return;
    }

    await this.app.parse.put(`/classes/Category/${id}`, data);

    Object.assign(category, data);

    this.app.notifications.success("Wert wurde geändert");
  }

  public async updateIndustry(
    id: string,
    key: keyof IndustryAttributes,
    value: any
  ) {
    const data = {
      [key]: value,
    };

    const industry = this.app.benchmarks.industries.find(
      (industry) => industry.objectId === id
    );

    if (!industry || industry[key] === value) {
      return;
    }

    await this.app.parse.put(`/classes/Industry/${id}`, data);

    Object.assign(industry, data);

    this.app.notifications.success("Wert wurde geändert");
  }

  public async updateRegion(
    id: string,
    key: keyof RegionAttributes,
    value: any
  ) {
    const data = {
      [key]: value,
    };

    const region = this.app.benchmarks.regions.find(
      (region) => region.objectId === id
    );

    if (!region || region[key] === value) {
      return;
    }

    await this.app.parse.put(`/classes/Region/${id}`, data);

    Object.assign(region, data);

    this.app.notifications.success("Wert wurde geändert");
  }

  public async create(className: string) {
    if (className === "_User") {
      return this.createUser();
    }

    const response = await this.app.parse.post(`/classes/${className}`, {});

    await this.app.benchmarks.init({});

    this.app.notifications.success("Element wurde erstellt");

    return response.objectId;
  }

  public async createUser() {
    const response = await this.app.parse.post(`/classes/_User`, {
      name: "Neuer Benutzer",
      username: "-",
      password: Math.random().toString(),
    });

    await this.init();

    this.app.notifications.success("Element wurde erstellt");

    return response.objectId;
  }

  public async delete(className: string, id: string) {
    if (window.confirm("Sicher dass das Element gelöscht werden soll?")) {
      const response = await this.app.parse.delete(
        `/classes/${className}/${id}`
      );

      if (className === "_User") {
        await this.init();
      } else {
        await this.app.benchmarks.init({});
      }

      this.app.notifications.success("Element wurde gelöscht");
    }
  }

  public async downloadContacts() {
    const response = await this.app.parse.cc("export-contacts");

    this.app.notifications.success("Kontakte werden heruntergeladen");

    this.exportToCsv("benchmarks.csv", response.result);
  }

  public async downloadBenchmarks() {
    const response = await this.app.parse.cc("export-benchmarks");

    this.app.notifications.success("Benchmarks werden heruntergeladen");

    this.exportToCsv("benchmarks.csv", response.result);
  }

  private async exportToCsv(filename: string, rows: any[][]) {
    const csvFile = rows
      .map((row) =>
        row
          .map((col) =>
            col == null
              ? ""
              : col instanceof Date
              ? col.toLocaleString()
              : col.toString()
          )
          .map((col) => col.replace(/"/g, '""'))
          .map((col) => (col.search(/("|;|\n)/g) >= 0 ? '"' + col + '"' : col))
          .join(";")
      )
      .join("\n");

    var blob = new Blob(["\ufeff" + csvFile], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
