import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { Navigate } from "react-router-dom";

export const LogoutRoute = observer(function LogoutRoute() {
  const app = useApp();

  React.useEffect(() => {
    app.parse.logout();
  }, []);

  if (!app.parse.user) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Berechtigungs Fehler"
        message="Du bist nicht eingeloggt"
      />
    );
  }

  return <Navigate to="/" />;
});
