import { autorun, makeObservable, observable } from "mobx";

import { UiService } from "./UiService";
import { NotificationService } from "./NotificationService";
import { ParseService } from "./ParseService";
import { BenchmarkService } from "./BenchmarkService";
import { ReportService } from "./ReportService";
import { AdminService } from "./AdminService";

export class AppService {
  private cache: any = null;

  public loading: boolean = true;

  public ui = new UiService(this);
  public notifications = new NotificationService(this);
  public parse = new ParseService(this);
  public benchmarks = new BenchmarkService(this);
  public report = new ReportService(this);
  public admin = new AdminService(this);

  constructor() {
    // @ts-ignore
    window.app = this;

    makeObservable(this, {
      loading: observable,
    });

    this.init();
  }

  private async init() {
    try {
      this.cache = JSON.parse(
        window.localStorage.getItem("benchmark.app.cache") || "null"
      );

      await this.ui.init(this.cache?.ui);
      await this.notifications.init(this.cache?.notifications);
      await this.parse.init(this.cache?.parse);
      await this.benchmarks.init(this.cache?.benchmarks);
      await this.report.init(this.cache?.report);
      await this.admin.init(this.cache?.admin);

      this.loading = false;

      autorun(() => {
        this.createCache();
      });
    } catch (error) {
      console.error(error);
    }
  }

  private createCache() {
    const result = {};
    const blacklist = ["loading", "cache"];

    for (const key of Object.keys(this)) {
      if (!blacklist.includes(key)) {
        // @ts-ignore
        result[key] = this[key];
      }
    }

    const json = JSON.stringify(
      result,
      (key, value) => {
        if (key === "app" && value instanceof AppService) {
          return undefined;
        } else if (key === "cache" && value === this.cache) {
        } else {
          return value;
        }
      },
      2
    );

    window.localStorage.setItem("benchmark.app.cache", json);
    console.log("benchmark.app.cache", JSON.parse(json));
  }
}
