import * as React from "react";

const packs: IconPack[] = [];

const iconSpanStyle: React.CSSProperties = {
  display: "inline-block",
  color: "inherit",
  fontStyle: "normal",
  lineHeight: "0",
  textAlign: "center",
  textTransform: "none",
  verticalAlign: "-.125em",
  textRendering: "optimizeLegibility",
};

export interface IconTree {
  tag: string;
  attr: { [key: string]: string };
  child: IconTree[];
}

export interface IconPack {
  set: string;
  theme: string;
  defaultTheme: boolean;
  icons: Record<string, IconTree>;
}

type PossibleESModule<T> = T | { default: T; __esModule: true };

export function registerIconPack(pack: PossibleESModule<IconPack>) {
  if ("default" in pack && "__esModule" in pack && pack.__esModule === true) {
    packs.push(pack.default);
  } else {
    packs.push(pack as IconPack);
  }
}

function treeToElement(tree: IconTree[]): React.ReactElement<{}>[] {
  return (
    tree &&
    tree.map((node, i) =>
      React.createElement(
        node.tag,
        { key: i, ...node.attr },
        treeToElement(node.child)
      )
    )
  );
}

export interface IconProps extends React.SVGAttributes<SVGElement> {
  icon: string;
  children?: React.ReactNode;
  size?: string | number;
  color?: string;
  title?: string;
}

export const Icon = React.memo<IconProps>(function Icon({
  icon,
  size = "1em",
  title,
  color,
  style = {},
  children,
  ...svgProps
}) {
  if (!icon) {
    throw new Error("Icon is missing icon definition");
  }

  const [pack, iconKey]: [IconPack, string] = React.useMemo(() => {
    const split = icon.split(":");

    if (split.length === 3) {
      const [set, theme, iconKey] = split;

      const pack = packs.find(
        (pack) => pack.set === set && pack.theme === theme
      );

      return [pack, iconKey];
    } else if (split.length === 2) {
      const [set, iconKey] = split;

      const pack =
        packs.find((pack) => pack.set === set && pack.defaultTheme === true) ||
        packs.find((pack) => pack.set === set);

      return [pack, iconKey];
    } else {
      return [null, null];
    }
  }, [icon, packs.length]);

  if (!pack) {
    console.warn(`IconPack for icon='${icon}' not found.`);
    return null;
  }

  const iconTree = pack.icons[iconKey];

  if (!iconTree) {
    console.warn(`Icon for icon='${icon}' not found.`);
    return null;
  }

  return (
    <span
      role="img"
      aria-label={iconKey}
      style={iconSpanStyle}
      className="icon"
    >
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        {...svgProps}
        {...iconTree.attr}
        style={{
          color,
          ...style,
        }}
        height={size}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        {title && <title>{title}</title>}
        {children}
        {treeToElement(iconTree.child)}
      </svg>
    </span>
  );
});
