import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";

import { useApp } from "../context";

export const AdminUserEditRoute = observer(function AdminUserEditRoute() {
  const app = useApp();

  const { id } = useParams();

  const user = app.admin.users.find((o) => o.objectId === id);

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Email"
        defaultValue={user?.email || ""}
        onBlur={(e) => {
          app.admin.updateUser(id, { email: e.target.value });
        }}
      />

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Passwort"
        type="password"
        autoComplete="off"
        helperText="Bitte frei lassen, wenn das Passwort nicht geändert werden soll. Wenn du dein eigenes Passwort änderst, musst du dich danach neu anmelden. Das gleiche gilt für andere Nutzer."
        defaultValue={user?.password || ""}
        onBlur={(e) => {
          app.admin.updateUser(id, { password: e.target.value });
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={!!user?.isAdmin}
            onChange={(e) => {
              app.admin.updateUser(id, { isAdmin: e.target.checked });
            }}
          />
        }
        label="Administrator"
      />

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={!!user?.notifications}
            onChange={(e) => {
              app.admin.updateUser(id, { notifications: e.target.checked });
            }}
          />
        }
        label="Benchmark Notifikationen"
      />
    </>
  );
});
